import React, { useState } from "react";
import { graphql } from "gatsby";

import { PARTNERS } from "../../../content/partners";
import CONTACTS from "../../../content/contact.json";

import { useTranslations } from "../../hooks/translations";
import SEO from "../../components/seo";
import Button from "../../components/button";
import Partner from "../../components/partner";
import ContactModal from "../../components/contact-modal";

import styles from "./partners.module.scss";

export const query = graphql`
  query PartnersQuery($locale: String!) {
    translations(locale: $locale) {
      pages {
        about {
          partners
        }
      }
      partners
      ...LayoutTranslations
    }
  }
`;

const PartnersPage = () => {
  const t = useTranslations("pages.about.partners");

  const partners = useTranslations("partners");

  const [contactModalVisible, setContactModalVisible] = useState(false);

  return (
    <>
      <SEO title={t.title} description={t.metaDescription} />

      <section className={`bp-alternative bp-background ${styles.section}`}>
        <div className="bp-opaque-background" />
        <div className={`bp-container ${styles.container}`}>
          <div className={styles.column}>
            <h1 className={styles.heading}>{t.heading}</h1>
          </div>

          <div className={styles.column}>
            <h1 className={styles.heading}>{t.becomePartnerHeading}</h1>
            <p className={`bp-accent ${styles.accent}`}>{t.becomePartnerDescription}</p>
            <Button
              className="bp-standard"
              onClick={() => setContactModalVisible(true)}
              aria-haspopup="dialog"
              aria-expanded={!!contactModalVisible}
            >
              {t.join}
            </Button>
          </div>
        </div>
      </section>

      <section className={styles.logoSection}>
        <div className="bp-container">
          <ul className={styles.list}>
            {PARTNERS.map(({ key, logo, url }) => (
              <Partner key={key} label={partners[key]} logo={logo} url={url} />
            ))}
          </ul>
        </div>
      </section>

      {contactModalVisible ? (
        <ContactModal
          onClose={() => setContactModalVisible(false)}
          message={t.contactModalHeading}
          email={CONTACTS.partnersEmail}
        />
      ) : null}
    </>
  );
};

export default PartnersPage;
